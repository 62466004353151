import {
  useEffect
} from 'react'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import { createClient, LiveTranscriptionEvents } from '@deepgram/sdk'

const filesDict = {
  first: '/first_user_reply.wav',
  second: '/second_user_reply.wav'
}

type Props = {
  onPhrase: (speech: string, dgLatency: number) => void
  dgKey: string
}

const RecorderDummy: React.FC<Props> = ({ onPhrase, dgKey }) => {
  useEffect(() => {
    const elt = document.getElementById('event_reply_div')
    const handleEvent = (e: CustomEvent<string>) => {
      console.log('event triggered', e)
      const audioId = get(e, 'detail')
      console.log('Handling file with audioId:', audioId)
      handleFile(audioId)
    }

    if (elt) {
      elt.addEventListener('ev_reply', handleEvent as EventListener)
      console.log('Event listener added for "ev_reply"')
    }

    const handleFile = async (audioId: string) => {
      console.log('Starting handleFile for audioId:', audioId)
      const file = get(filesDict, audioId)
      if (isNil(file)) {
        console.log('No file found for audioId:', audioId)
        return
      }

      console.log('File found. Fetching and sending data.')
      try {
        const audioArrayBuffer = await fetchAudioFile(file)
        await sendToDeepgram(audioArrayBuffer)
      } catch (error) {
        console.error('Error handling file:', error)
      }
    }

    const sendToDeepgram = async (audioArrayBuffer: ArrayBuffer) => {
      const deepgram = createClient(dgKey)
      const dgParams = {
        model: 'nova-2',
        smart_format: true,
        endpointing: 300,
        interim_results: true,
        utterance_end_ms: 1200,
        diarize: true,
        punctuate: true,
        sample_rate: 16000,
        channels: 1,
        encoding: 'linear16',
        language: 'en-US',
        numerals: true,
        vad_events: true
      }

      const dgConnection = deepgram.listen.live(dgParams)

      let finalTranscription = ''

      dgConnection.on(LiveTranscriptionEvents.Open, () => {
        console.log('Deepgram connection opened.')
        console.log('Sending audio data to Deepgram:', audioArrayBuffer.byteLength)
        dgConnection.send(audioArrayBuffer)
      })

      dgConnection.on(LiveTranscriptionEvents.Transcript, (data) => {
        const transcript = data.channel.alternatives[0].transcript
        console.log('Transcript received:', transcript, 'Is final:', data.is_final)
        if (data.is_final) {
          finalTranscription = transcript
        }
      })

      dgConnection.on(LiveTranscriptionEvents.Error, (err) => {
        console.error('Deepgram error:', err)
      })

      dgConnection.on(LiveTranscriptionEvents.Close, () => {
        console.log('Deepgram connection closed')
        if (finalTranscription.trim()) {
          console.log('Final transcription:', finalTranscription.trim())
          onPhrase(finalTranscription.trim(), 0)
        } else {
          console.log('No transcription results')
        }
      })

      setTimeout(() => {
        console.log('Requesting to close Deepgram connection')
        dgConnection.requestClose()
      }, 2000)
    }

    return () => {
      if (elt) {
        elt.removeEventListener('ev_reply', handleEvent as EventListener)
        console.log('Event listener removed for "ev_reply"')
      }
    };
  }, [dgKey, onPhrase])

  const fetchAudioFile = async (audioFile: string): Promise<ArrayBuffer> => {
    console.log('Fetching audio file:', audioFile)
    const response = await fetch(audioFile)
    if (!response.ok) {
      throw new Error(`Failed to fetch audio file: ${response.statusText}`)
    }
    const audioArrayBuffer = await response.arrayBuffer()
    console.log('Audio file fetched, size:', audioArrayBuffer.byteLength)
    return audioArrayBuffer
  }

  return null
}

export default RecorderDummy
