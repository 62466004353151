import {
  useRef,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useEffect,
  useState
} from 'react'
import jsLogger from 'js-logger'
import isNil from 'lodash/isNil'

type Props = {
  onAvatarPlayingFinished: (latency: number) => void
  thereWasAnError?: string
  isRecording: boolean
  handleVideoClick: () => void
  onSessionStarted: () => void
  permissionsGranted: boolean
}

const CustomAvatarConnect: ForwardRefRenderFunction<any, Props> = (
  {
    onAvatarPlayingFinished,
    thereWasAnError,
    isRecording,
    handleVideoClick,
    onSessionStarted,
    permissionsGranted
  },
  ref
) => {
  const imgRef = useRef<HTMLImageElement>(null)
  const audioRef = useRef<HTMLAudioElement>(null)
  const [isFirstTimePlaying, setIsFirstTimePlaying] = useState<boolean>(true)

  useEffect(() => {
    if (permissionsGranted && isFirstTimePlaying) {
      onSessionStarted()
      setIsFirstTimePlaying(false)
    }
  }, [onSessionStarted, permissionsGranted, isFirstTimePlaying])

  useImperativeHandle(ref, () => ({
    say: (text: string) => {
      startTalk(text)
    },
    cancel: () => null,
    stop: async () => null,
    play: () => null,
  }))

  const avatarCompleted = () => {
    jsLogger.log('avatar finished talking')
    onAvatarPlayingFinished(0)
    if (!isNil(imgRef.current)) {
      imgRef.current.removeEventListener('load', handleImageLoad)
      imgRef.current.src = 'custom_avatar_idle.gif'
    }
  }

  const startTalk = async (msg: string) => {
    jsLogger.debug('startTalk', { msg })
  
    try {
      const sResultRaw = await fetch(
        `https://avatar.facefile.co/findanswer?val=${msg}`
      )
      const sResult = await sResultRaw.json()
      console.log('sResult', sResult)
  
      const audioElement = audioRef.current
      if (audioElement) {
        const audioResponse = await fetch(sResult.audio_path)
        const audioBlob = await audioResponse.blob()
        audioElement.src = URL.createObjectURL(audioBlob)
  
        audioElement.onended = () => {
          avatarCompleted()
        }
  
        if (imgRef.current) {
          imgRef.current.src = `https://avatar.facefile.co/videostream?${new Date().getTime()}`
          imgRef.current.addEventListener('load', handleImageLoad)
        }
      }
    } catch (e) {
      jsLogger.warn('Error in startTalk', e)
    }
  }

  const handleImageLoad = () => {
    const audioElement = audioRef.current
    if (audioElement) {
      audioElement.play().catch((e) => console.log('Error playing audio', e))
    }
  }

  return (
    <div
      className='h-full w-full flex flex-col justify-between flex-1 max-w-2xl relative overflow-hidden'
      onClick={handleVideoClick}
    >
      <audio ref={audioRef} />

      <div className='w-full h-full absolute left-0 top-0 right-0 bottom-0 bg-white flex'>
        <img
          alt='avatar'
          ref={imgRef}
          src='custom_avatar_idle.gif'
          className='w-full h-full object-cover flex self-end'
        />
      </div>
      {isRecording && (
        <div className='absolute top-0 left-0 bottom-0 right-0 bg-black/60 w-full h-full' />
      )}
      {thereWasAnError && (
        <div className='absolute bottom-0 left-0 bg-white'>
          <p className='text-red'>{thereWasAnError}</p>
        </div>
      )}
    </div>
  )
}

export default forwardRef(CustomAvatarConnect)